<script>
import Layout from "../../layouts/main";
import CKEditor from "@ckeditor/ckeditor5-vue";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import VueSimpleComplete from "vue-simple-complete";
import "@/assets/scss/buttons_content.scss";
import Swal from "sweetalert2";
import vue2Dropzone from "vue2-dropzone";
import { Carousel, Slide } from 'vue-carousel';

const axios = require('axios').default;

const brandController = require('@/controllers/endpoint/brands')
const clientController = require('@/controllers/endpoint/clients')
const contentController = require('@/controllers/endpoint/content_plannings')
const postsController = require('@/controllers/endpoint/content_posts')
const categoriesController = require('@/controllers/endpoint/category')
const userController = require('@/controllers/endpoint/user')
const urlController = require('@/controllers/endpoint/url')
const commentController = require('@/controllers/endpoint/comment')
const formatController = require('@/controllers/endpoint/format')
const historyController = require('@/controllers/endpoint/history')

export default {
    components: { Layout, ckeditor: CKEditor.component, VueSimpleComplete, vueDropzone: vue2Dropzone, Carousel, Slide},
  data() {
    return {
      dropzoneOptionscarrousel: {
        url: '/',
        thumbnailWidth: 200,
        withCredentials: false, 
        dictDefaultMessage: 'Drop an image here or click to select an image to upload',
        headers: {
          "Cache-Control": "",
        },        
      },
    preview:{
      image_url:''
    },
    basepathCRM: process.env.VUE_APP_BASEPATH_CRM,
    basepathENDPOINT: process.env.VUE_APP_BASEPATH_ENDPOINT,
    user:'',
    title: '',
    spinner:false,
    contentId:this.$route.params.id,
    brand:[],
    client:[],
    content:[],
    posts:[],
    option_share_selected:"",
    selected_url: [], // Must be an array reference!
    options_url: [
       
    ],
    options_select: [
          { value: '', text: 'Please select an option' },
        ],
    options_share: [
          { value: '', text: 'Please select an option' },
          { value: "all", text: 'All content' },
          { value: "ads", text: 'Content Ads' },
          { value: "organic", text: 'Content Organic' },
          { value: "select-creative", text: 'Select creatives' },
        ],
    url_type:"",
    url:{
      brand:'',
      brands:'',
    },
    content_new: {
            name: "",
            content_planning_id: "",
            copy_in: "",
            copy_out: "",
            category: "",
            type: 1,
            format: "",
            utm: "",
            url_resources: "",
            rol:8
    },
    content_edit: {
            name: "",
            image_data:"",
            copy_in: "",
            copy_out: "",
            category: "",
            type: 1,
            format: "",
            utm: "",
            url_resources: "",
            rol:8
    },
    categories: [],
            objectMatchkey: "name",
            template: {
                keys: ["name"],
            },
    editor: ClassicEditor,
    titleModal:"",
    dropzoneOptions: {
        addRemoveLinks: true,
        url: "https://httpbin.org/post",
        maxFilesize:10,
        //maxFiles: 1,
        acceptedFiles: ".jpeg,.jpg,.png,.gif",
        //headers: { "My-Awesome-Header": "header value" }
      },
      urls:[],
      comments:[],
      selected: 'id',
        options: [
          { value: 'id', text: 'ID' },
          { value: 'copy_in', text: 'Copy In' },
          { value: 'copy_out', text: 'Copy Out' },
        ],
        optionsFormat:[
          { value: 'all', text: 'All Formats' },
        ],
        filter_creatives: {
          content_planning_id: this.$route.params.id,
          selected:"all",
          type: 'all',
          format:'all',
          order:'asc',
          visibility:'0',
          search:'id',
          searchInput:''
        },
        status_history:[],
        post_selected: null
    };
  },
  created() {
    this.userData()
    this.contentData()
    this.postsData()
    this.categoriesData()
    this.urlData()
    this.commentData("ASC")
    this.getFormat(),
    this.getStatus_history("ASC")
  },
  methods: {
    getFormat(){
    const formatContent = formatController.list("asc");
        formatContent.then((response) => {   //Succesfully result
        this.options_select = this.options_select.concat(response.data);
        this.optionsFormat = this.optionsFormat.concat(response.data);
    })
      .catch((err) => {    //Error result
        console.error(err);
      });
    },
    getCreatives_type(filter, type, value, sort){
       const postContent = postsController.list(this.contentId, filter, type, value, sort);
        postContent.then((response) => {   //Succesfully result
        this.posts = response.data;
        setTimeout(() => this.spinner = false, 1000);
    })
      .catch((err) => {    //Error result
        console.error(err);
      });
    },
    commentData(order){
        const dataComment = commentController.list(this.contentId, order);
        dataComment.then((response) => {   //Succesfully result
        this.comments = response.data;
    })
      .catch((err) => {    //Error result
        console.error(err);
      });
    },
    vibilityFilters(){
      if(this.filter_creatives.visibility == '0'){
        this.filter_creatives.visibility = '1'
      }else if(this.filter_creatives.visibility == '1'){
                this.filter_creatives.visibility = '0'
      }
      
    },
    userData(){
        const dataUser = userController.dataUser();
        dataUser.then((response) => {   //Succesfully result
        this.user = response.data[0];
    })
      .catch((err) => {    //Error result
        console.error(err);
      });
    },
     onInputChanged(value) {
            this.content_new.category = value.toUpperCase();
        },
        brandData(brand_id){
        const dataClient = brandController.findID(brand_id);
        dataClient.then((response) => {   //Succesfully result
        this.brand = response.data[0];
        this.clientData(this.brand.client_id)
    })
      .catch((err) => {    //Error result
        console.error(err);
      });
    },
      clientData(client_id){
        const dataClient = clientController.findID(client_id);
        dataClient.then((response) => {   //Succesfully result
        this.client = response.data[0];
        this.linkUrl()
    })
      .catch((err) => {    //Error result
        console.error(err);
      });
    },
    contentData(){
        const dataContent = contentController.findID(this.contentId);
        dataContent.then((response) => {   //Succesfully result
        this.content = response.data[0];
        this.brandData(this.content.brand_id)
    })
      .catch((err) => {    //Error result
        console.error(err);
      });
    },
    postsData(){
        const postContent = postsController.list(this.contentId);
        postContent.then((response) => {   //Succesfully result
        this.posts = response.data;
        setTimeout(() => this.spinner = false, 1000);
    })
      .catch((err) => {    //Error result
        console.error(err);
      });
    },
    linkUrl(){
      let brandName = this.brand.name.replace(/ /g,"-").toLowerCase();
      let clientName = this.client.name.replace(/ /g,"-").toLowerCase();
      let urlBrand = `/brand/${brandName}/${this.brand.id}`
      let urlBrands = `/client/${clientName}/brands/${this.client.id}`
      this.url = {
        brand:urlBrand,
        brands:urlBrands,
        brandName:`${this.brand.name.charAt(0).toUpperCase()}${this.brand.name.slice(1)}`
      }
      this.title = this.brand.name.toUpperCase()
    },
     categoriesData(){
       const categoriesData = categoriesController.list();
        categoriesData.then((response) => { 
        this.categories = response.data;
    })
      .catch((err) => {    //Error result
        console.error(err);
      });
    },
    categoriesPost(){
       const categoriesData = categoriesController.list();
        categoriesData.then((response) => { 
        this.categories = response.data;
    })
      .catch((err) => {    //Error result
        console.error(err);
      });
    },
    open_modalContent(){
      this.$refs['new-content-modal'].show()
      this.resetData()
    },
    close_modalContent(ref){
        this.$refs[ref].hide()
    },
    alert(message, variant){
          this.$bvToast.toast(`${message}`, {
        title: "BRAND ALERT",
        variant: variant,
        solid: true
      });
    },
    addContent(){
        this.content_new.content_planning_id = this.contentId
        const dataPost = postsController.create(this.content_new.content_planning_id, this.content_new.copy_in, this.content_new.copy_out, this.content_new.category, this.content_new.type, this.content_new.utm, this.content_new.format);
        dataPost.then((response) => {   //Succesfully result
        let data = response.data.description;
        this.addCategory()
        this.alert(data, 'success')
        this.close_modalContent('new-content-modal')
        this.resetData()
        this.spinner = true;
        this.postsData()
    })
      .catch((err) => {    //Error result
        console.error(err);
      });
    },
      deleteContent(id){
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ml-2"
        },
        buttonsStyling: false
      });

      swalWithBootstrapButtons
        .fire({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          confirmButtonText: "Yes, delete it!",
          cancelButtonText: "No, cancel!",
          showCancelButton: true
        })
        .then(result => {
          if (result.value) {
            let data =    {
                "id": id,
                "rol":this.user.rol
            }
        const deletePost = postsController.delete(data);
        deletePost.then((response) => {   //Succesfully result
        response
        swalWithBootstrapButtons.fire(
              "Deleted!",
              "Post has been deleted.",
              "success"
            );
        this.resetData()
        this.spinner = true;
        this.postsData()
    })
      .catch((err) => {    //Error result
        console.error(err);
      });
            
          } else if (
            /* Read more about handling dismissals below */
            result.dismiss === Swal.DismissReason.cancel
          ) {
            swalWithBootstrapButtons.fire(
              "Cancelled",
              "Your Post is safe",
              "error"
            );
          }
        });
    },
    changeType(type){
      this.content_new.type = type;
      if(this.content_new.type == 0){
      document.getElementById("organic-button").style.background = '#247ba0';
      document.getElementById("ads-button").style.background = '#011c27';

      }else if(this.content_new.type == 1){
      document.getElementById("ads-button").style.background = '#247ba0';
      document.getElementById("organic-button").style.background = '#011c27';
      }
    },
    urlData(){
        const urlData = urlController.list(this.contentId);
        urlData.then((response) => { 
        this.urls = response.data;
    })
      .catch((err) => {    //Error result
        console.error(err);
      });
    },
    resetData(){
      this.content_new = {
            name: "",
            content_planning_id: "",
            copy_in: "",
            copy_out: "",
            category: "",
            type: 1,
            format: "",
            utm: "",
            url_resources: "",
            rol:8
      }
    },
    addCategory(){
      let data = {
        "name": this.content_new.category,
        "id_brand": this.brand.id
      }
      const dataCategory = categoriesController.create(data);
        dataCategory.then((response) => {
        let data = response.data.description;
        data
       this.categoriesData()
    })
      .catch((err) => {   
        console.error(err);
      });
    },
     modal_add_Image(id){
     this.content_edit.id = id
     this.titleModal = `CHANGE IMAGE POST`
     this.titleModal = this.titleModal.toUpperCase()
     this.$refs['add-image-modal-content'].show()
   },
   convertImage(file){
        return new Promise(function(resolve, reject) {
        let reader = new FileReader();
        reader.onload = function() { resolve(reader.result); };
        reader.onerror = reject;
        reader.readAsDataURL(file);
    });
   },
   getImage(file) {
      const base64 = this.convertImage(file);
      base64.then((response) => { 
        this.addImage({
          id: this.content_edit.id,
          image_data: response,
        })
    })
  },
  sendingImage: function (file) {    
    //formData.append('id', this.content_edit.id);
    this.getImage(file)
  },
   addImage(data) {
      const addPost = postsController.upload_image(data);
        addPost.then(() => {   //Succesfully result
        //let data = response.data.description;
        //this.alert(data, 'success')
        //this.resetData()
        //this.spinner = true;
        //this.postsData()
        //this.content_edit.image_data = ""
       //this.$refs['add-image-modal-content'].hide()
    })
      .catch((err) => { 
         this.alert(err, 'danger')   //Error result
      });
   },
   modal_edit_Post(id, copy_in, copy_out, category, utm, type, format){
      this.content_new = {
        "id":id, 
        "copy_in":copy_in, 
        "copy_out":copy_out, 
        "category":category, 
        "utm":utm, 
        "type":type,
        "format":format
      };
      console.log(this.content_new.category)
      this.content_new.rol = this.user.rol 
      this.$refs['edit-content-modal'].show()
   },
   editPost(){
        const dataPost = postsController.update(this.content_new.id, this.content_new.copy_in, this.content_new.copy_out, this.content_new.category, this.content_new.type, this.content_new.utm, this.content_new.format);
        dataPost.then((response) => {   //Succesfully result
        let data = response.data.description;
        this.addCategory()
        this.alert(data, 'success')
        this.close_modalContent('edit-content-modal')
        this.resetData()
        this.spinner = true;
        this.postsData()
    })
      .catch((err) => {    //Error result
        console.error(err);
      });
   },
  createURL(){
    
    if(this.option_share_selected == 'select-creative'){
    const dataCategory = urlController.create(this.content.brand_id, this.contentId, "selected-creatives", this.selected_url.toString());
        dataCategory.then((response) => {
        response
        this.urlData()
        this.close_modalContent('share-modal')
    })
      .catch((err) => {   
        console.error(err);
    });
    }else{
    const dataCategory = urlController.create(this.content.brand_id, this.contentId, this.option_share_selected, "null");
        dataCategory.then((response) => {
        response
        this.urlData()
        this.close_modalContent('share-modal')
    })
      .catch((err) => {   
        console.error(err);
    });
    }
     
  },
  previewImage(postSelected){
  //this.preview.image_url = url;
  this.post_selected = postSelected;
  this.$refs['preview-content-modal'].show()
   },
   downloadImg(url) {
            axios({
                    url: url,
                    method: 'GET',
                    responseType: 'blob',
                }).then((response) => {
                     var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                     var fURL = document.createElement('a');
   
                     fURL.href = fileURL;
                     fURL.setAttribute('download', 'creative.png');
                     document.body.appendChild(fURL);
   
                     fURL.click();
                });
    },
  modal_add_Image_carrousel(){

  },
  formatOptions_url(){
    this.selected_url = []
    this.options_url = this.posts
    for (let i = 0; i < this.options_url.length; i++) {
    this.options_url[i].text = "#"+this.options_url[i].id
    this.options_url[i].value = this.options_url[i].id
     }
     console.log(this.selected_url)
  },
   copyURL(url) {
      navigator.clipboard.writeText(url);
       this.$bvToast.toast(`URL Copied`, {
        title: "Clipboard",
        variant: "success",
        solid: true
      }); 
  },
  modal_edit_carrousel(){
          this.$refs['carrousel-content-modal'].show()
  },
  add_image_carrusel(){

  },
  getPostfilter(){
         if(this.filter_creatives.searchInput.length == 0){
                this.filter_creatives.selected = 'all'
                 const postContent = postsController.creativesfilter(this.filter_creatives);
        postContent.then((response) => {   //Succesfully result
        this.posts = response.data;
    })
      .catch((err) => {    //Error result
        console.error(err);
      });    
            }else if(this.filter_creatives.searchInput.length > 0){
               this.filter_creatives.selected = 'search'
               const postContent = postsController.creativesfilter(this.filter_creatives);
               postContent.then((response) => {   //Succesfully result
               this.posts = response.data;
               })
               .catch((err) => {    //Error result
                  console.error(err);
               });
            }       
      
  },
  getStatus_history(order){
    const dataHistory = historyController.list(order, this.contentId,);
        dataHistory.then((response) => {   //Succesfully result
        this.status_history = response.data;
    })
      .catch((err) => {    //Error result
        console.error(err);
      });
  }
  },
  computed:{
    disabledForm(){
            return this.content_new.copy_in == '' || this.content_new.copy_out == '' || this.content_new.format == ''
    },
    disabledImageForm(){
            return this.brandData.image_data == ''
    },
     disabledUrl(){
            return this.option_share_selected == ''
    }
  },
};
</script>
<style scoped>
.table-td-url{
  border-bottom: 1px solid #d3d3d3 !important;
}
  .button-url-content{
    border: 2px solid #595959;
    position: relative;
    background-color:transparent !important;
    display: inline-flex;
    vertical-align: middle;
    border-radius: 10px;
    color: #011c27 !important;
    font-family: "Inter", sans-serif !important;
    font-weight:400 !important;
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 14px;
  }
    .button-url-content:hover{
    border: 2px solid #011c27;
    position: relative;
    background-color:#011c27 !important;
    display: inline-flex;
    vertical-align: middle;
    border-radius: 10px;
    color: white !important;
    font-family: "Inter", sans-serif !important;
    font-weight:400 !important;
    padding-top: 7px;
    padding-bottom: 7px;
    font-size: 14px;
  }
.status-box-approved {
    font-weight: 600;
    background-color: #247ba0;
    color: white;
    font-family: nunito;
    border-radius: 5px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 12px;
}
.status-box-pending {
    font-weight: 600;
    background-color: #011c27;
    color: white;
    font-family: nunito;
    border-radius: 5px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 12px;
}
.table th, .table td {
    border-bottom: 2px solid #d3d3d3;
  }
  .table thead th {
      border-bottom: 1px solid #e9e9e9;
      border-top: 1px solid #ffffff;
  }
  table th, .table td {
      border-top: 1px solid #ffffff;
  }
  .tr-table-brand{
  font-family: nunito;
  color: #595959;
  font-weight: 600;
  } 
  .tbody-table-brand{
  font-family: "Inter", sans-serif;
  color: #011c27;
  font-weight: 500;
}
table th, .table td {
    vertical-align: middle;
}
.button-new-client{
    border: 2px solid #247ba0;
    background-color:#247ba0 !important;
    border-radius: 10px;
    color: white;
    font-family: "Inter", sans-serif !important;
    font-weight: 400 !important;
    font-size: 14px;
    padding-top: 7px;
    padding-bottom: 7px;
  }
.button-new-client:hover{
    border: 2px solid #011c27;
    background-color: #ffffff !important;
    border-radius: 10px;
    color: #595959 !important;
    font-family: "Inter", sans-serif !important;
    font-weight: 400 !important;
    font-size: 14px;
    padding-top: 7px;
    padding-bottom: 7px;
  }
.vue-dropzone > .dz-preview .dz-remove {
    font-size: 8px !important;
}
.dz-image img{width: 100%;height: 100%;}
.btn--svg-share:hover .btn--svg__label-share {
    color: black;
}
.dz-progress{
display: none;
}
.button-content-form{
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 8px;
    padding-bottom: 8px;
    color: #f9faff !important;
    background-color: #247ba0 !important;
    font-weight: bold !important;;
    border-radius: 30px !important;
    border: 2px solid #247ba0;
}
.button-content-form:hover{
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 8px;
    padding-bottom: 8px;
    color: #f9faff !important;
    background-color: #262626 !important;
    font-weight: bold !important;
    border-radius: 30px !important;
    border: 2px solid #f9faff;
}
.btn-group > .btn:not(:first-child), .btn-group > .btn-group:not(:first-child) > .btn {
    border-top-right-radius: 30px;
    border-bottom-right-radius: 30px;
    background-color: #011c27;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle), .btn-group > .btn-group:not(:last-child) > .btn {
    padding-right: 40px;
    padding-left: 40px;
    background-color: #247ba0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    padding-top: 10px;
    border-top-left-radius: 30px;
    border-bottom-left-radius: 30px;
    padding-bottom: 10px;
}

.anchore-menu{
color:#f9faff;
font-weight: bold;
}
.anchore-menu:hover{
color:#247ba0;
font-weight: bold;
}
.anchore-url{
color:#011c27;
font-weight: bold;
}
.anchore-url:hover{
color:#247ba0;
cursor: pointer;
 font-family: "Inter", sans-serif;
  font-weight: 500;
}
.anchore-menu {
  color:#011c27;
  font-family: "Inter", sans-serif;
  font-weight: 500;
}
.anchore-menu:hover {
  color:#247ba0;
  font-family: "Inter", sans-serif;
  font-weight: 500;
}
.second-menu {
pointer-events: none;
color:#595959;
font-family: "Inter", sans-serif;
font-weight: 500;
}
.title-content {
  font-size: 40px;
  margin: 0 0 0px 0;
  color: #011c27;
  margin-bottom: 30px;
  font-family: nunito;
  font-weight: 600;
  pointer-events: none;
}
.dot-content {
  pointer-events: none;
  color:#247ba0;
  font-family: nunito;
  font-weight: 600
}
.table-content{
  border-radius: 30px !important;
}
.button-content{
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 15px;
    padding-bottom: 15px;
    color: #f9faff !important;
    background-color: #247ba0 !important;
    font-weight: bold !important;
    border-radius: 30px !important;
    border: 2px solid #247ba0;
}
.button-content:hover{
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 15px;
    padding-bottom: 15px;
    color: #f9faff !important;
    background-color: #262626 !important;
    font-weight: bold !important;
    border-radius: 30px !important;
    border: 2px solid #f9faff;
}
.container-content {
  position: relative;
  width: 50%;
}

.image {
  opacity: 1;
  display: block;
  width: 100%;
  height: auto;
  transition: .5s ease;
  backface-visibility: hidden;
}

.middle-content {
  transition: .5s ease;
  opacity: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.container-content:hover .image {
  opacity: 0.3;
}

.container-content:hover .middle-content {
  opacity: 1;
}

.icon-image-content {
  background-color: #0000008F;
    color: #f9faff;
    font-size: 16px;
    margin-left: 20px;
    padding-top: 15px;
}
.icon-action-content{
    display: inline-block;
    width: 40px;
    height: 40px;
    line-height: 36px;
    font-size: 14px;
    color: #f9faff;
    border: 1px solid #f9faff;
    border-radius: 30px;
    transition: all .4s;
    text-align: center;
    margin-right: 16px;
    vertical-align: middle;
    background-color: #247ba0;
    cursor: pointer;
}
.icon-action-content:hover{
    border: 1px solid #011c27;
    background-color: #011c27;
    cursor: pointer;
}
.id-box-content{
    font-weight: 600;
    background-color: #011c27;
    color: white;
    font-family: nunito;
    border-radius: 5px;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 15px;
    padding-right: 15px;
    font-size: 20px;
}
</style>
<template>
  <Layout>
    <div style="padding-left:30px; padding-right:30px">
    <div class="text-lg-left">
          <ul class="list-inline mb-0">
            <li class="list-inline-item">
                <router-link :to="`${url.brands}`" class="font-w600 text-dual">
<a class="anchore-menu">Brands</a>
                </router-link>
            </li>
            <li class="list-inline-item">
        <i style="color:#3521b5;" class="fas fa-angle-right"></i>
            </li> 
            <li class="list-inline-item">
                <router-link :to="`${url.brand}`" class="font-w600 text-dual">
<a class="anchore-menu">{{url.brandName}}</a>
                </router-link>
            </li> 
             <li class="list-inline-item">
        <i style="color:#3521b5;" class="fas fa-angle-right"></i>
            </li>
            <li class="second-menu list-inline-item">
                   Content Planning
            </li>         
          </ul>
        </div>
        <div class="row">
            <div  class="col-lg-6 text-lg-left">
          <h5 class="title-content">{{this.title}} <span class="dot-content">{{this.content.month}}</span><span style="font-weight:100;">/</span><span class="dot-content">{{this.content.year}}</span>.</h5>
            </div>
            <div  class="button-div col-lg-6 text-lg-right" style="padding-right: 0px !important;">
                    <button v-on:click="open_modalContent()" class="button-new-client" style="">Add new creative</button>
            </div>      
        </div>
        <b-modal ref="new-content-modal" id="popup-form-content" style="font-family:nunito;" size="xl" hide-footer centered title="New creative">
             <b-button-group style="margin-bottom:15px;">
                  <b-button v-on:click="changeType(1)" id="ads-button">ADS</b-button>
                  <b-button v-on:click="changeType(0)" id="organic-button">ORGANIC</b-button>
               </b-button-group>
                <div class="row">
                  <div class="col-lg-6">
                      <div class="form-group">
                      <label for="name">Copy In<span style="color:#247ba0">*</span></label>
                        <ckeditor v-model="content_new.copy_in" :editor="editor"></ckeditor>                    
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label for="description">Copy Out<span style="color:#247ba0">*</span></label>
                        <ckeditor v-model="content_new.copy_out" :editor="editor"></ckeditor>                    
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <div class="form-group">
                        <label for="website">Category</label>
                         <div class="input-group">
                     <vue-simple-complete class="category-input"
                      :items="categories"
                      :objectMatchkey="objectMatchkey"
                      :template="template"
                      @inputChanged="onInputChanged"
                      ></vue-simple-complete>
                    </div>
                    </div>
                  </div>
                   <div class="col-lg-5">
                    <div class="form-group">
                      <label for="description">Format<span style="color:#247ba0">*</span></label>
                      <b-select :options="options_select" v-model="content_new.format" type="text" class="form-control" id="description" placeholder="" />
                    </div>
                  </div>
                  <div class="col-lg-5">
                    <div class="form-group">
                      <label for="description">UTM</label>
                      <input v-model="content_new.utm" type="text" class="form-control" id="description" placeholder="" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <div class="text-left">
                      <button v-on:click="addContent()" :disabled='disabledForm' class="btn button-content-form btn-primary">ADD NEW</button>
                    </div>
                  </div>
                </div>
            </b-modal>
 <div style="padding-top:150px; " v-if="spinner == true" class="text-center">
            <b-spinner style="width: 3rem; height: 3rem; color:#011c27;" type="grow" label="Text Centered Large Spinner"></b-spinner>
        </div>
        <div class="row" v-if="spinner == false">
      <div class="col-lg-12">
            <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3 text-muted">
              <b-tab active>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Creatives</span>
                </template>
                 <div class="row">
            <div  class="col-lg-6 text-lg-left">
          <b-input-group class="mb-2">
    <b-input-group-prepend is-text>
                <i class="ri-search-eye-line align-middle"></i>
                
    </b-input-group-prepend>
    <b-form-input @input="getPostfilter()" v-model="filter_creatives.searchInput"  size="10" placeholder="Search..."></b-form-input>
     <template #append>
       <b-form-select @input="getPostfilter()" style="border-radius: 0px" v-model="filter_creatives.search" :options="options"></b-form-select>
    </template>
  </b-input-group>
            </div>
              <div style="margin-top:10px;" class="col-lg-6 text-lg-right">
                <div>
                  <button style="background-color:white; cursor: pointer; border: 2px solid rgb(89, 89, 89); padding-top: 5px; padding-bottom: 5px; padding-left: 10px; padding-right: 14px; border-radius: 10px; color: black;" v-on:click="vibilityFilters()">
                           <i  class="ri-filter-3-line"></i>
                         Filters
                                           </button>
                                            </div>

            </div>
            
        </div>
         <div v-if="filter_creatives.visibility == 1" style="background-color:#eff2f7; padding-top:15px; margin-left:2px; margin-top:15px; margin-bottom:25px; border-radius:8px;" class="row">
            <div  class="col-lg-4 text-lg-left">
          <b-form-select v-model="filter_creatives.type" @change="getPostfilter()" class="mb-3">
      <b-form-select-option  value="all" >All creatives</b-form-select-option>
      <b-form-select-option value="1">Ads creatives</b-form-select-option>
      <b-form-select-option value="0">Organic creatives</b-form-select-option>
    </b-form-select>
            </div>
             <div  class="col-lg-4 text-lg-left">
          <b-form-select :options="optionsFormat" v-model="filter_creatives.format" @change="getPostfilter()" class="mb-3">
    </b-form-select>
            </div>
             <div  class="col-lg-4 text-lg-left">
          <b-form-select v-model="filter_creatives.order" @change="getPostfilter()" class="mb-3">
           <b-form-select-option value="asc" >Asc sorting</b-form-select-option>
           <b-form-select-option value="desc">Desc sorting</b-form-select-option>
    </b-form-select>
            </div>
        </div>
           
                 <b-modal id="modal-add-carrousel-image" size="lg" title="charge of Images" ref="carrousel-content-modal" centered ok-only ok-title="Close">
                  <vue-dropzone 
                                id="dropzone-product-images"
                                ref="myVueDropzone"         
                                acceptedFileTypes="image/*"
                                :options="dropzoneOptionscarrousel" 
                                @vdropzone-complete="add_image_carrusel()"
                  ></vue-dropzone>
                </b-modal>
                 <div class="table-responsive">
              <table class="table mb-0">
                <thead>
                  <tr>
                    <th width="2%" style="text-align: center;">ID</th>
                    <th>Format</th>
                   <th width="20%">Creative Info</th>
                    <th width="5%">Type</th>
                    <th width="28%">Status</th>
                    <th scope="row">Creative</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(post, index) in posts" :key="index">
                    <td><span class="id-box-content">{{post.id}}</span></td>
                    <th><span>{{post.format}}</span></th>
                    <td><b>Copy In</b><span v-html="post.copy_in">{{post.copy_in}}</span> <br><b>Copy Out</b> <span v-html="post.copy_out">{{post.copy_out}}</span></td>
                    <td><span class="status-box-pending" v-if="post.type == false">Organic</span><span v-if="post.type == true" class="status-box-approved">Ads</span>
                    </td>
                    <td  ><span class="status-box-pending" v-if="post.status == 0">Pending</span><span v-if="post.status == 1" class="status-box-approved">Approved by {{post.emailClient_status}}</span>
              <br v-if="post.status == 1"><br v-if="post.status == 1"><span v-if="post.status == 1" class="status-box-approved">{{post.status_date}}</span><span v-if="post.status == 2" class="status-box-approved">Disapproved by {{post.emailClient_status}}</span><br v-if="post.status == 2"><br v-if="post.status == 2"><span v-if="post.status == 2" class="status-box-approved">{{post.status_date}}</span></td>
                    <td>
                      <!-- Conservar -->
                      <img v-if="!post.images.length" src="@/assets/images/image-placeholder.png" style="width:100%; height:auto;"/>
                      <!----> 
                      <!-- Conservar -->
                      <!--<img v-if="post.images.length" :src="`${basepathENDPOINT}${post.images[0].url}`" style="width:100%; height:auto;"/>-->
                      <!---->

                      <carousel 
                        v-if="post.images.length > 0"
                        :per-page="1" 
                        :autoplay="true" 
                        :loop="true"
                        :centerMode="true"
                        :autoplayTimeout="5000"
                      >
                        <slide v-for="(image, index) in post.images" :key="index">
                          <img :src="`${basepathENDPOINT}${image.url}`" style="width:100%; max-height:250px;"/>
                        </slide>
                      </carousel>
                    </td>
                    <td  >
                      <div class="text-center row">
                       <div  class="col-lg-12"><i v-on:click="modal_edit_Post(post.id, post.copy_in, post.copy_out, post.category, post.utm, post.type, post.format)" style="margin-top:10px; padding-top:2px;" class="icon-action-content  fas fa-pen"></i></div>
                            <!-- Conservar -->
                       <div  class="col-lg-12"><i v-on:click="modal_add_Image(post.id)" style="margin-top:10px; padding-top:2px;" class="icon-action-content fas fa-images"></i></div>
                           <!---->  
                       <div  class="col-lg-12"><i v-if="post.image_url !== null" v-on:click="previewImage(post)" style="margin-top:10px; padding-top:2px;" class="icon-action-content  fas fa-eye"></i></div>
                       <div v-on:click="deleteContent(post.id)" class="col-lg-12"><i v-if="user.rol > 0 && user.rol <= 14" style="margin-top:10px; padding-top:2px;" class="icon-action-content fas fa-trash"></i></div>
                       <div class="col-lg-12"><i v-if="post.image_url !== null" v-on:click="downloadImg(`${basepathENDPOINT}${post.image_url}`)" style="margin-top:10px; padding-top:2px;" class="icon-action-content  fas fa-file-download"></i></div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
              </b-tab>
              <b-tab>
                    <div class="button-div col-lg-12 text-lg-right">
          <b-dropdown toggle-class='dropdown-clients' variant='none' id="dropdown-clients" text="Dropdown Button">
                          <template style="background-color:black;" slot="button-content">
                           <i class="ri-filter-3-line"></i>
                            Filters
                          </template>
                          <b-dropdown-item v-on:click="commentData('ASC')">Asc</b-dropdown-item>
                          <b-dropdown-item v-on:click="commentData('DESC')">Desc</b-dropdown-item>
                         </b-dropdown>
      </div>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-cog"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Comments</span>
                </template>
             
                
                   <div class="table-responsive">
              <table class="table mb-0">
                <thead>
                  <tr>
                    <th width="2%" style="text-align: center;">ID</th>
                    <th width="30%" >Comment</th>
                    <th width="20%">ID Creative</th>
                    <th>Client</th>
                    <th width="30%">Date</th>
                  </tr>
                </thead>
                <tbody v-for="(comment, index) in comments" :key="index">
                  <tr>
                    <td style="vertical-align: middle;"><span class="id-box-content" >{{comment.id}}</span></td>
                    <th style="vertical-align: middle !important;">{{comment.comment}}</th>
                    <td style="vertical-align: middle !important;"><span class="id-box-content" >{{comment.content_post_id}}</span></td>
                    <td style="vertical-align: middle !important;">{{comment.emailClient}}</td>
                    <td>{{comment.dateCreated}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
              </b-tab>
              <b-tab title="Status history">
    <div class="table-responsive">
              <table class="table mb-0">
                <thead>
                  <tr>
                    <th width="2%" style="text-align: center;">ID</th>
                    <th width="20%">ID Creative</th>
                    <th>Client</th>
                    <th width="30%" >Status</th>                 
                    <th width="30%">Date</th>
                  </tr>
                </thead>
                <tbody v-for="(history, index) in status_history" :key="index">
                  <tr>
                    <td style="vertical-align: middle;"><span class="id-box-content" >{{history.id}}</span></td>
                    <th style="vertical-align: middle !important;"><span class="id-box-content" >{{history.idPost}}</span></th>
                    <td style="vertical-align: middle !important;">{{history.emailClient}}</td>
                    <td style="vertical-align: middle !important;"><span class="status-box-pending" v-if="history.status == 0">Pending</span><span v-if="history.status == 1" class="status-box-approved">Approved by {{history.emailClient}}</span>
              <span v-if="history.status == 2" class="status-box-approved">Disapproved by {{history.emailClient}} </span></td>
                    <td>{{history.date}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-cog"></i>
                  </span>
                  <span class="d-none d-sm-inline-block">Shared URL</span>
                </template>
                 <div  class="col-lg-12 text-lg-right">
                <button v-b-modal.share-modal class="button-url-content">Add new URL</button>
            </div>
                 <div class="table-responsive">
              <table class="table mb-0">
                <thead>
                  <tr>
                    <th width="2%" style="text-align: center;">ID</th>
                    <th>URL</th>
                   <th  >Date</th>
                    <th >Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(url, index) in urls" :key="index">
                    <td class="table-td-url"><span class="id-box-content">{{url.id}}</span></td>
                    <td class="table-td-url"><a :href="`${basepathCRM}${url.url}`">{{basepathCRM}}{{url.url}}</a></td>
                    <td class="table-td-url">{{url.createdAt.substring(0,10)}}</td>
                    <td style="border-bottom: 1px solid #d3d3d3 !important;" class="table-td-url"><button @click="copyURL(`${basepathCRM}${url.url}`)" class="button-url-content" style="margin-right:10px;">Copy</button> 
                    <router-link :to="`${url.url}`" class="font-w600 text-dual">
                    <button class="button-url-content" >View</button></router-link></td>
                  </tr>
                </tbody>
              </table>
            </div>
<b-modal ref="share-modal" id="share-modal" hide-footer centered>
        <div class="row">
            <div class="col-lg-12">
                <div class="form-group">
                    <label for="description">Select the type of content you want to share</label>
                    <b-select @change="formatOptions_url()" :options="options_share" v-model="option_share_selected" type="text" class="form-control" id="description" placeholder="" />
                </div>
                <b-form-group v-if="option_share_selected == 'select-creative'"
      label="Select the id of the creatives you want to share:"
      v-slot="{ ariaDescribedby }"
    >
      <b-form-checkbox-group
        v-model="selected_url"
        :options="options_url"
        :aria-describedby="ariaDescribedby"
        name="flavour-2a"
        stacked
      ></b-form-checkbox-group>
    </b-form-group>
            </div>
            
            <div class="col-lg-12">
              <button v-on:click="createURL()" :disabled='disabledUrl'  class="btn button-content-form btn-primary">CREATE URL</button>
            </div>
          </div>
</b-modal>
         
              </b-tab>
            </b-tabs> 
            <b-modal ref="add-image-modal-content" hide-footer centered :title="`${titleModal}`">
              <vue-dropzone                 
                ref="myVueDropzone" 
                id="dropzone" 
                :options="dropzoneOptions" 
                @vdropzone-sending="sendingImage"
                @vdropzone-success="postsData()"
              ></vue-dropzone>
                <div class="text-right" style="padding-top:10px;">
                  <button v-on:click="close_modalContent('add-image-modal-content')" class="btn button-brands-form btn-primary">CERRAR</button>
                </div>
            </b-modal>
            <b-modal ref="preview-content-modal" hide-footer centered>
              <carousel 
                v-if="post_selected"
                :per-page="1" 
                :autoplay="true" 
                :loop="true"
                :centerMode="true"
                :autoplayTimeout="4000"
              >
                <slide v-for="(image, index) in post_selected.images" :key="index">
                  <img :src="`${basepathENDPOINT}${image.url}`" style="width:100%; height:auto;"/>
                </slide>
              </carousel>
            </b-modal>
            <b-modal ref="edit-content-modal" id="popup-form-content-edit" style="font-family:nunito;" size="xl" hide-footer centered title="Edit creative">
             <b-button-group style="margin-bottom:15px;" v-if="content_new.type == false">
                  <b-button style="background-color:#011c27;" v-on:click="changeType(1)" id="ads-button">ADS</b-button>
                  <b-button style="background-color:#247ba0;"  v-on:click="changeType(0)" id="organic-button">ORGANIC</b-button>
             </b-button-group>
             <b-button-group style="margin-bottom:15px;" v-if="content_new.type == true">
                  <b-button style="background-color:#247ba0;" v-on:click="changeType(1)" id="ads-button">ADS</b-button>
                  <b-button style="background-color:#011c27;" v-on:click="changeType(0)" id="organic-button">ORGANIC</b-button>
             </b-button-group>
                <div class="row">
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label for="name">Copy In<span style="color:#247ba0">*</span></label>
                        <ckeditor v-model="content_new.copy_in" :editor="editor"></ckeditor>                    
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="form-group">
                      <label for="description">Copy Out<span style="color:#247ba0">*</span></label>
                        <ckeditor v-model="content_new.copy_out" :editor="editor"></ckeditor>                    
                    </div>
                  </div>
                  <div class="col-lg-2">
                    <div class="form-group">
                        <label for="website">Category</label>
                        <div class="input-group">
                     <vue-simple-complete class="category-input"
                      :items="categories"
                      :objectMatchkey="objectMatchkey"
                      :template="template"
                      @inputChanged="onInputChanged"
                      ></vue-simple-complete>
                    </div>
                    </div>
                  </div>
                    <div class="col-lg-5">
                    <div class="form-group">
                      <label for="description">Format<span style="color:#247ba0">*</span></label>
                      <b-select :options="options_select" v-model="content_new.format" type="text" class="form-control" id="description" placeholder="" />
                    </div>
                  </div>
                  <div class="col-lg-5">
                    <div class="form-group">
                      <label for="description">UTM</label>
                      <input v-model="content_new.utm" type="text" class="form-control" id="description" placeholder="" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-lg-12">
                    <div class="text-left">
                      <button v-on:click="editPost()" :disabled='disabledForm' class="btn button-content-form btn-primary">ADD NEW</button>
                    </div>
                  </div>
                </div>
            </b-modal>
      </div>
    </div>
    </div>
  </Layout>
</template>
<style>
.nav-tabs-custom .nav-item .nav-link.active {
    color: white;
    font-family: 'Nunito';
    font-weight: 600;
    background-color: black !important;
    border-bottom: 1px solid white;
}
.nav-tabs-custom .nav-item .nav-link::after {
    background: black;
}
.sc__filtered-items{
  background-color: #f9faff !important;
}
[type=search]{
  display: block;
    width: 100%;
    height: calc(1.5em + 0.94rem + 2px);
    padding: 0.47rem 0.75rem;
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.5;
    color: #011c27;
    background-color: #f9faff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.swal2-confirm{
  background-color: #247ba0 !important;
}
.swal2-confirm:active{
  border-color: #247ba0 !important;
  background-color: #247ba0 !important;
}
.swal2-confirm::selection{
  border-color: #247ba0 !important;
  background-color: #247ba0 !important;
}
p {
    margin-bottom: 0px !important;
}
</style>