const axios = require('axios').default;

module.exports = {
async search(type, value){
      try {
         if(type == "id"){
            let data = {"id": value}
            let apiResponse = await axios.post(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/content-planning/find/id`, data) 
            return apiResponse
         }else if(type == "creator"){
            let data = {"creator": value}
            let apiResponse = await axios.post(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/content-planning/find/creator`, data) 
            return apiResponse
         }
         else if(type == "month"){
            let data = {"month": value}
            let apiResponse = await axios.post(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/content-planning/find/month`, data) 
            return apiResponse
         }
         else if(type == "year"){
            let data = {"year": value}
            let apiResponse = await axios.post(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/content-planning/find/year`, data) 
            return apiResponse
         }
         else if(type == "week"){
            let data = {"week": value}
            let apiResponse = await axios.post(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/content-planning/find/week`, data) 
            return apiResponse
         }
         else if(type == "range"){
            let data = {"range": value}
            let apiResponse = await axios.post(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/content-planning/find/range`, data) 
            return apiResponse
         }
         else if(type == "objective"){
            let data = {"objective": value}
            let apiResponse = await axios.post(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/content-planning/find/objective`, data) 
            return apiResponse
         }
       } catch (error) {
         return error
      }
},
async findID(contentId){
   let data = {"id":contentId}
   try {
      const apiResponse = await axios.post(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/content-planning/find/id`, data) 
      return apiResponse
    } catch (e) {
      return e
   }
},
async listPaginated(brand_id,page,sort){
   let data = {
      "brand_id": brand_id,
      "sort": sort
   }
   try {
      let apiResponse = await axios.post(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/content-planning/list/paginate?page=${page}`, data)
      return apiResponse.data
    } catch (error) {
      return error
   }
},
async create(data){
   try {
      let apiResponse = await axios.post(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/content-planning/create`, data) 
      return apiResponse
    } catch (error) {
      return error
   }
},

async delete(payload){
   try {
      const apiResponse = await axios.delete(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/content-planning/delete`,{
         headers: {},
         data: payload,
       }) 
      return apiResponse
    } catch (e) {
      return e
   }
},
async update(data){
   try {
      let apiResponse = await axios.post(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/content-planning/update`, data) 
      return apiResponse
    } catch (error) {
      return error
   }
},
}