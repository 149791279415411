const axios = require('axios').default;

module.exports = {
async create(data){
   try {
      const apiResponse = await axios.post(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/format/create`, data) 
      return apiResponse
    } catch (e) {
      return e
   }
},
async list(order, content_planning_id){
   let data =    {
    "order": order,
    "content_planning_id":content_planning_id
}
   try {
      const apiResponse = await axios.post(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/approval-history/list`, data) 
      return apiResponse
    } catch (e) {
      return e
   }
},
}