const axios = require('axios').default;

module.exports = {
async create(payload){
   let data =    {
    "name": payload.name,
    "id_brand": payload.id_brand
}
console.log(data)
   try {
      const apiResponse = await axios.post(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/category/create`, data) 
      return apiResponse
    } catch (e) {
      return e
   }
},
async list(){
   try {
      const apiResponse = await axios.get(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/category/list`) 
      return apiResponse
    } catch (e) {
      return e
   }
},
}