const axios = require('axios').default;

module.exports = {
async create(creative, idClient, emailClient){
    console.log("send",creative)
   let data =    {
    "content_post_id": creative.id_post,
    "comment": creative.comment,
    "content_planning_id": creative.post_content_planning_id,
    "idClient": idClient,
    "emailClient": emailClient,
}
   try {
      const apiResponse = await axios.post(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/comment-client/create`, data) 
      return apiResponse
    } catch (e) {
      return e
   }
},
async list(content_planning_id, order){
   let data =    {
    "content_planning_id": content_planning_id,
    "order":order
}
   try {
      const apiResponse = await axios.post(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/comment-client/list`, data) 
      return apiResponse
    } catch (e) {
      return e
   }
},
}