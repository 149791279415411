const axios = require('axios').default;

module.exports = {
async create(brand_id, content_planning_id, type, posts_select){
   let data =    {
    "brand_id": brand_id,
    "content_planning_id": content_planning_id,
    "type": type,
    "posts_select":posts_select,
    "rol":8
}
   try {
      const apiResponse = await axios.post(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/generated-url/create`, data) 
      return apiResponse
    } catch (e) {
      return e
   }
},
async list(content_planning_id){
   let data =    {
    "content_planning_id": content_planning_id,
}
   try {
      const apiResponse = await axios.post(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/generated-url/list`, data) 
      return apiResponse
    } catch (e) {
      return e
   }
},
}