const axios = require('axios').default;

module.exports = {
    async list(content_planning_id){
        let data = {"content_planning_id": content_planning_id}
        try {
           let apiResponse = await axios.post(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/content-post/list`, data) 
           return apiResponse
         } catch (error) {
           return error
        }
     },
     async search(type, value){
      try {
         if(type == "id"){
            let data = {"id": value}
            let apiResponse = await axios.post(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/content-post/find/id`, data) 
            return apiResponse
         }else if(type == "utm"){
            let data = {"utm": value}
            let apiResponse = await axios.post(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/content-post/find/utm`, data) 
            return apiResponse
         }
       } catch (error) {
         return error
      }
   },
     async all_list(page){
      try {
         let apiResponse = await axios.get(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/content-post/list/all/?page=${page}`) 
         return apiResponse
       } catch (error) {
         return error
      }
   },
     async create(content_planning_id, copy_in, copy_out, category, type, utm, format){
      let data = {
         "name": "",
         "content_planning_id": content_planning_id,
         "copy_in": copy_in,
         "copy_out": copy_out,
         "category": category,
         "type": type,
         "utm": utm,
         "url_resources": "",
         "format":format,
         "rol":8
      }
      try {
         let apiResponse = await axios.post(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/content-post/create`, data) 
         return apiResponse
       } catch (error) {
         return error
      }
   },
   async update(id, copy_in, copy_out, category, type, utm, format){
      let data = {
         "id":id,
         "copy_in": copy_in,
         "copy_out": copy_out,
         "category": category,
         "type": type,
         "utm": utm,
         "format":format,
         "url_resources": "",
         "rol":8
      }
      
      try {
         let apiResponse = await axios.post(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/content-post/update`, data) 
         return apiResponse
       } catch (error) {
         return error
      }
   },
   async delete(data){
      try {
         let apiResponse = await axios.delete(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/content-post/delete`, {
            headers: {},
            data: data,
          }) 
         return apiResponse
       } catch (error) {
         return error
      }
},
async upload_image(data){
   try {
      let apiResponse = await axios.post(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/content-post/upload_image`, data) 
      return apiResponse
    } catch (error) {
      return error
   }
},

async change_status(creative, status, idclient, emailclient){
   console.log(idclient, emailclient)
   let data = {
      "id": creative.id,
      "content_planning_id": creative.content_planning_id,
      "status": status,
      "idclient": idclient,
      "emailclient": emailclient,
      "type": creative.type,
      "format": creative.format
   }
   
   try {
      let apiResponse = await axios.post(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/content-post/status`, data) 
      return apiResponse
    } catch (error) {
      return error
   }
},
async status_all(type, status, creatives, idClient, emailClient){
   let data = {
      "creatives":creatives,
      "status":status,
      "idclient": idClient,
      "emailclient": emailClient,
      "type":type,
}
   
   try {
      let apiResponse = await axios.post(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/content-post/status-all`, data) 
      return apiResponse
    } catch (error) {
      return error
   }
},
async creativesAll(filter, type, value, sort){
   try {
      if(type == "id"){
         let data = {
            "id": value,
            "sort":sort,
            "type":type
            }
         let apiResponse = await axios.post(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/content-post/find/id`, data) 
         return apiResponse
      }else if(type == "utm"){
         let data = {
            "utm": value,
            "sort":sort,
            "type":type
         }
         let apiResponse = await axios.post(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/content-post/find/utm`, data) 
         return apiResponse
      }
    } catch (error) {
      return error
   }

},
async creativesfilter(data){
   console.log(data)
   try {
         let apiResponse = await axios.post(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/content-post/list/all/filter`, data) 
         return apiResponse  
    } catch (error) {
      return error
   }
},
async creativestype(filter, type, value, sort){
   try {
      if(filter == "id"){
         let data = {
         "id": value,
         "sort":sort,
         "type":type
         }
         let apiResponse = await axios.post(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/content-post/find/id`, data) 
         return apiResponse
      }else if(filter == "utm"){
         let data = {
         "utm": value,
         "sort":sort,
         "type":type
      }
         let apiResponse = await axios.post(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/content-post/find/utm`, data) 
         return apiResponse
      }
    } catch (error) {
      return error
   }

}
}