const axios = require('axios').default;

module.exports = {
async create(data){
   try {
      const apiResponse = await axios.post(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/format/create`, data) 
      return apiResponse
    } catch (e) {
      return e
   }
},
async list(order){
   let data =    {
    "order": order,
}
   try {
      const apiResponse = await axios.post(`${process.env.VUE_APP_BASEPATH_ENDPOINT}/format/list`, data) 
      return apiResponse
    } catch (e) {
      return e
   }
},
}